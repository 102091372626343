import React from "react";

const SortDescending = (props: {
  height: number;
  width: number;
  fill: string;
}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 12 13"
    width={props.width}
    height={props.height}
    fill={props.fill}
    preserveAspectRatio="xMinYMin"
    className="jam jam-eye-close-f"
  >
    <path d="M1.79922 2.2998C1.46785 2.2998 1.19922 2.56843 1.19922 2.89981C1.19922 3.23118 1.46785 3.49981 1.79922 3.49981H8.39922C8.73059 3.49981 8.99922 3.23118 8.99922 2.89981C8.99922 2.56843 8.73059 2.2998 8.39922 2.2998H1.79922Z" />
    <path d="M1.79922 4.69981C1.46785 4.69981 1.19922 4.96844 1.19922 5.29981C1.19922 5.63118 1.46785 5.89981 1.79922 5.89981H5.99922C6.33059 5.89981 6.59922 5.63118 6.59922 5.29981C6.59922 4.96844 6.33059 4.69981 5.99922 4.69981H1.79922Z" />
    <path d="M1.79922 7.09981C1.46785 7.09981 1.19922 7.36844 1.19922 7.69981C1.19922 8.03118 1.46785 8.29981 1.79922 8.29981H4.19922C4.53059 8.29981 4.79922 8.03118 4.79922 7.69981C4.79922 7.36844 4.53059 7.09981 4.19922 7.09981H1.79922Z" />
    <path d="M8.99922 5.29981C8.99922 4.96844 8.73059 4.69981 8.39922 4.69981C8.06785 4.69981 7.79922 4.96844 7.79922 5.29981L7.79922 8.65129L7.02348 7.87555C6.78917 7.64123 6.40927 7.64123 6.17495 7.87555C5.94064 8.10986 5.94064 8.48976 6.17495 8.72408L7.97495 10.5241C8.08748 10.6366 8.24009 10.6998 8.39922 10.6998C8.55835 10.6998 8.71096 10.6366 8.82348 10.5241L10.6235 8.72408C10.8578 8.48976 10.8578 8.10986 10.6235 7.87555C10.3892 7.64123 10.0093 7.64123 9.77495 7.87555L8.99922 8.65129L8.99922 5.29981Z" />
  </svg>
);

export default SortDescending;
